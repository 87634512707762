import Vue from 'vue'
import VueRouter from 'vue-router'
import VueHead from 'vue-head'

Vue.use(VueHead)
Vue.use(VueRouter)

export const dynamicHead = {
  data() {
    return {
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
        schema: null,
      },
      exportData: [],
      list: [],
    }
  },
  'head': function () {
    return {
      title: () => {
        return {inner: this.params.title && this.params.title.length ? this.params.title : 'David Roytman',
                separator: ' ',
                complement: ' '}
      },
      meta:  () => {
        return [
          {n: 'keywords', content: this.params.keywords, id: 'keywords'},
          {p: 'og:title', content: this.params.title, id: 'og:title'},
          {p: 'og:description', content: this.params.description, id: 'og:description'},
          {p: 'og:image', content: this.params.image, id: 'og:image'},
          {p: 'og:url', content: window.location.origin + this.$route.path, id: 'og:url'},
          {p: 'twitter:title', content: this.params.title, id: 'twitter:title'},
          {p: 'twitter:description', content: this.params.description, id: 'twitter:description'},
          {p: 'twitter:image', content: this.params.image, id: 'twitter:image'},
          {n: 'title', content: this.params.title, id: 'title'},
          {n: 'description', content: this.params.description, id: 'description'},
        ]
      },
      link: () => {
        let route = this.$route.fullPath
        if (route.slice(1, 4) === 'ru/' || route.slice(1, 4) === 'en/' || route.slice(1, 4) === 'es/' || route.slice(1, 4) === 'fr/' || route.slice(1, 4) === 'iw/') {
          route = route.slice(3)
        }
        return [
          {rel: 'canonical', href: window.location.origin + this.$route.fullPath, id: 'canonical'},
          {rel: 'alternate', hreflang: 'ru', href: window.location.origin + '/ru' + route, id: 'ru'},
          // {rel: 'alternate', hreflang: 'es', href: window.location.origin + '/es' + route, id: 'es'},
          // {rel: 'alternate', hreflang: 'fr', href: window.location.origin + '/fr' + route, id: 'fr'},
          // {rel: 'alternate', hreflang: 'iw', href: window.location.origin + '/iw' + route, id: 'iw'},
          {rel: 'alternate', hreflang: 'en', href: window.location.origin + route, id: 'en'},
          {rel: 'alternate', hreflang: 'x-default', href: window.location.origin + route, id: 'x-default'},
        ]
      },
      script: ()=> {
        let org = {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "David Roytman",
            "url": "https://davidroytman.com/",
            "logo": "https://davidroytman.com/logo.svg",
            "foundingDate": "2015",
            "contactPoint": [
              {"@type": "ContactPoint", "email": "sales@davidroytman.com", "contactType": "Sales"},
              {
                "@type": "ContactPoint",
                "telephone": "+1(347)244-5461",
                "contactType": "Customer Support",
              },
              {
                "@type": "ContactPoint",
                "telephone": "+(972)548-030-290",
                "contactType": "Customer Support",
              }
            ],
            "sameAs": [
              "https://www.instagram.com/david_roytman_usa",
              "https://www.youtube.com/c/DavidRoytmanLuxurySuitcasesAccessories",
              "https://www.facebook.com/DavidRoytmanUSA",
              "https://www.tiktok.com/@davidroytman"
            ]
          }
        this.exportData.push({id: 'organization', t: 'application/ld+json', i: JSON.stringify(org)})
        return this.exportData
      }
    }
  },
  watch: {
    '$route'() {
      this.setMeta()
    },
    product: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.setMeta()
        }
      }
    }
  },
  methods: {
    setMeta() {
      this.$emit('updateHead');
    },
    setCatalogSchema(){
      this.exportData.push({id: 'ItemList', t: 'application/ld+json', i: this.params.schema})
      this.$emit('updateHead');
    },
    setProductSchema(){
      this.exportData.push({id: 'Product', t: 'application/ld+json', i: this.params.schema})
      this.$emit('updateHead');
    }
  }
}